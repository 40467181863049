var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Chọn lớp khôi phục cho học sinh",
        visible: _vm.dialogVisible,
        width: "500px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataInput",
          attrs: {
            "label-width": "120px",
            model: _vm.dataInput,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c("el-form-item", { attrs: { label: "Tên học sinh" } }, [
            _c("span", [_vm._v(" " + _vm._s(_vm.dataTransfer.fullName) + " ")]),
          ]),
          _c("el-form-item", { attrs: { label: "Lớp hiện tại" } }, [
            _c("span", [
              _vm._v(" " + _vm._s(_vm.dataTransfer.maClass.className) + " "),
            ]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "Chọn lớp", prop: "idClass" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.dataInput.idClass,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataInput, "idClass", $$v)
                    },
                    expression: "dataInput.idClass",
                  },
                },
                _vm._l(_vm.classList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.className, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.save")))]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }